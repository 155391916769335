import { postRequest, getRequest } from './index'

// OA审批的分页
export const auditInfoQueryByPage = (data, successCallback, failureCallback) => {
  postRequest('/oa/auditInfo/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// OA审批的分页  审核接口
export const auditInfoAudit = (data, successCallback, failureCallback) => {
  postRequest('/oa/auditInfo/audit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// OA审批的分页  审核接口
export const auditInfoQueryBydetail = (data, successCallback, failureCallback) => {
  postRequest('/oa/auditInfo/queryBydetail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 流程监控图
export const taskGetDiagram = (data, successCallback, failureCallback) => {
  getRequest(`/task/getDiagram?activiteId=${data.activiteId}&currentNodeId=${data.currentNodeId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
