<template>
  <div class="fileUploadPageBox">
    <el-upload
      v-if="!file.fileUrl && !file.fileName"
      ref="upload"
      :action="$baseUpload"
      :headers="headers"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
      :on-success="handleAvatarSuccess"
      :on-error="uploadError"
      :limit="limit"
    >
      <el-button icon="el-icon-upload2" :loading="loading" plain type="primary" size="small">
        上传附件
      </el-button>
    </el-upload>
    <div v-else class="fileBox">
      <span class="elp-1">{{ file.fileName }}</span>
      <i class="icon el-icon-close" @click="clearFile" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editFileName: {
      default: '',
      type: String
    },
    limit: {
      default: 1,
      type: Number
    },
    fileSize: {
      default: 0,
      type: Number
    },
    fileFormat: {
      default: '',
      type: String
    }
    // loading: {
    //   default: false,
    //   type: Boolean
    // }
  },
  data() {
    return {
      loading: false,
      headers: {
        token: sessionStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'tokenCust' : 'tokenAgent'}`)
      },
      file: { fileName: '', fileUrl: '' }
    }
  },
  watch: {
    editFileName: {
      handler(newVal) {
        this.file.fileName = newVal
        if (!newVal) this.file.fileUrl = ''
      }
    }
  },
  methods: {
    uploadError() {
      this.loading = false
    },
    beforeAvatarUpload(file) {
      const fileSize = file.size / 1024 / 1024 < this.fileSize
      const typeArr = file.name.split('.')
      if (this.fileFormat && !(this.fileFormat.indexOf(typeArr[1]) >= 0)) {
        this.$message.error(`文件格式只能是 ${this.fileFormat} 格式!`)
        return false
      }
      if (this.fileSize > 0 && !fileSize) {
        this.$message.error(`文件大小不能超过 ${this.fileSize}MB!`)
        return false
      }
      this.loading = true
      return true
    },
    handleAvatarSuccess(res, file) {
      this.loading = false
      this.$refs.upload.clearFiles()
      if (res.code !== 200) {
        this.$message.error(res.msg)
        return
      }
      this.file.fileName = file.name
      this.file.fileUrl = res.data
      this.$emit('getFile', this.file)
    },
    clearFile() {
      this.file = {
        fileName: '',
        fileUrl: ''
      }
      this.$emit('getFile', this.file)
    }
  }
}
</script>
<style lang="scss">
.fileUploadPageBox{
  display: flex;
  align-items: center;
  .fileBox{
    display: flex;
    align-items: center;
    span{
      width: calc(100% - 28px);
    }
  }
  .icon{
    padding-left: 18px;
    color: $mainFontColor;
    font-size: 16px;
    cursor: pointer;
  }
  .icon:hover{
    color: $pointClearColor;
  }
  .el-button {
    color: $mainFontColor;
    border-color: $mainBorderColor;
    &:focus,
    &:hover {
        color: $mainBlue;
        background-color: #fff;
        border-color: $mainBlue;
      }
  }
}
</style>
