<template>
  <div class="auditPageBox">
    <el-dialog title="审核" width="30%" :visible.sync="dialogAuditFormVisible" :close-on-click-modal="false">
      <el-form ref="audit" :model="auditForm" label-width="80px">
        <el-form-item
          label="审核"
          prop="state"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' }
          ]"
        >
          <el-select v-model="auditForm.state" placeholder="请选择" @change="$forceUpdate()">
            <el-option
              v-for="item in auditOptionItem"
              :key="item.value||item.VARIABLE_CODE"
              :label="item.label||item.VARIABLE_NAME"
              :value="item.value||item.VARIABLE_CODE"
            />
          </el-select>
        </el-form-item>
        <slot name="middleSlot" />
        <el-form-item
          label="备注"
          prop="remark"
          :rules="auditForm.state==='approve_refuse'?[{ required: true, message: '请填写', trigger: 'blur' }]:[]"
        >
          <el-input v-model="auditForm.remark" type="textarea" maxlength="20" show-word-limit autocomplete="off" @input="$forceUpdate()" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain class="widen-button" @click="dialogAuditFormVisible = false">
          取 消
        </el-button>
        <el-button size="small" type="primary" class="widen-button" :loading="$store.state.loading" @click="determineAudit('audit')">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    auditOptionItem: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      auditForm: {},
      dialogAuditFormVisible: false
    }
  },
  watch: {
    dialogAuditFormVisible: {
      handler(newVal) {
        if (newVal) {
          this.auditForm = {}
          return
        }
        if (this.$refs['audit']) {
          this.$nextTick(() => {
            this.$refs['audit'].resetFields()
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    determineAudit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('reviewSubmitted', this.auditForm)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.auditPageBox {
  .dialog-footer{
      text-align: center!important;
    }
}
</style>
